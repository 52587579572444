<template>
    <a v-if="href" :href="href" :class="['btn', buttonSize, buttonVariant]" rel="noopener noreferrer" @click="handleClick">
        <span v-if="icon" class="btn-icon">
            <i :class="icon"></i>
        </span>
        <slot></slot>
    </a>
    <button v-else :class="['btn', buttonSize, buttonVariant]" @click="handleClick">
        <span v-if="icon" class="btn-icon">
            <i :class="icon"></i>
        </span>
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'CustomButton',
    props: {
        href: {
            type: String,
            default: '#'
        },
        size: {
            type: String,
            default: 'large',
            validator: (value) => ['small', 'large'].includes(value)
        },
        variant: {
            type: String,
            default: 'primary',
            validator: (value) => ['primary', 'secondary', 'primary-white', 'outlined-white'].includes(value)
        },
        icon: {
            type: String,
            default: null
        },
        targetId: {
            type: String,
            default: null
        }
    },
    computed: {
        buttonSize() {
            return this.size === 'large' ? 'btn-large' : 'btn-small';
        },
        buttonVariant() {

            if(this.variant === 'primary'){
                return 'btn-primary';
            } else if(this.variant === 'primary-white'){
                return 'bg-white text-[#AB1700]'
            } else if(this.variant === 'outlined-white'){
                return 'border border-white text-white rounded-full hover:bg-red-700'
            }
            return 'btn-secondary';
        }
    },
    methods: {
        handleClick(event) {
            if (this.targetId) {
                event.preventDefault(); 
                const element = document.getElementById(this.targetId);
                if (element) {
                    const offset = 72; 
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }
        }
    }
}
</script>

<style scoped>
.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    padding: 8px 16px;
    transition: all 0.3s;
    font-weight: 600;
    text-decoration: none;
    z-index: 1;
}

/* Primary button styles */
.btn-primary {
    background-color: #AB1700;
    color: white;
    border: none;
}

/* Secondary button styles */
.btn-secondary {
    background-color: transparent;
    color: #AB1700;
    border: 2px solid #AB1700;
}

/* Default font sizes for mobile */
.btn-large {
    font-size: 16px;
    padding: 12px 24px;
}

.btn-small {
    font-size: 14px;
    padding: 8px 16px;
}

/* Desktop font sizes */
@media (min-width: 768px) {
    .btn-large {
        font-size: 18px;
    }

    .btn-small {
        font-size: 16px;
    }
}

/* Icon styling */
.btn-icon {
    margin-right: 12px;
    display: flex;
    align-items: center;
}

.btn-icon i {
    font-size: 1.25em;
}
</style>

