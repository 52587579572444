<template>
    <div class="p-4 border border-red-200 rounded-lg shadow-sm bg-white text-center">
        <div>
            <h2 class="mb-2 text-left text-[30px]">{{ emoji }}</h2>
        </div>
      <h4 class="text-left text-gray-800 font-medium">{{title}}</h4>
    </div>
  </template>
  
  <script>
  export default {
    name: "ServicesCard",
    props: {
      title: {
        type: String,
        default: "Tiket Pesawat PP",
      },
      emoji: {
        type: String,
        default: "🛩️",
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  