<template>
    <div class="min-[320px]:pb-4">
        <div>
            <img :src="image" class="rounded-lg mb-4 w-[250px] max-w-max">
        </div>

        <div class="flex space-x-2 py-2 justify-start mb-2">
            <LabelCard v-for="(lbl, index) in labels"
                :key="index"
                :label-name="lbl.title"
                :style-class="lbl.style"/>
        </div>

        <div class="text-left">
            <h3 class="font-semibold text-gray-800">{{title}}</h3>
            <p class="text-gray-600">{{subtitle}}</p>
        </div>

    </div>

</template>

<script>

import LabelCard from './Labels.vue';

export default {
  name: "PackageCard",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    labels: {
        type: Array,
        required: true
    },
    image: {
      type: String,
      required: true
    },
  },
  components:{
    LabelCard
  }
}
</script>