<template>
    <div id="services-section" class="px-8 my-6">
        <h2 class="font-semibold mb-4">Layanan Utama Kisarah</h2>
        <div class="grid grid-cols-5 min-[320px]:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4">
            <ServicesCard
                v-for="(equipment, index) in kisarahData.services"
                :key="index"
                :title="equipment.title"
                :emoji="equipment.icon"
            />
        </div>
    </div>

</template>

<script>
import ServicesCard from '../ServicesCard.vue';

export default {
  name: "ServicesSection",
  components: {
    ServicesCard
  },
  data() {
    return {
        kisarahData: {
            equipment: [
                {
                    "title":"Hijab",
                    "img_url":"https://awsimages.detik.net.id/community/media/visual/2023/11/21/pemenang-emeron-hijab-hunt-2023-menjalani-ibadah-umroh-2_43.jpeg?w=1200"
                },
                {
                    "title":"Kain Ihram",
                    "img_url":"https://www.static-src.com/wcsstore/Indraprastha/images/catalog/full//101/MTA-36502399/no_brand_baju_ihrom_dewasa_kain_ihram_satu_stel_bahan_handuk_full01_p0457ws1.jpg"
                },
                {
                    "title":"Buku Doa-Doa",
                    "img_url":"https://toko-muslim.com/images/product/2021/05/1.png"
                }
            ],
            services: [
                {
                    "title":"Tiket Pesawat PPT",
                    "icon":"🛩️"
                },
                {
                    "title":"Tur Ziarah Mekkah & Madinah",
                    "icon":"🕌"
                },
                {
                    "title":"Handling Bagasi di Bandara",
                    "icon":"🧳"
                },
                {
                    "title":"Layanan Muthawif",
                    "icon":"🤲"
                },
                {
                    "title":"Asuransi Umrah",
                    "icon":"☂️"
                },
                {
                    "title":"Akomodasi Hotel",
                    "icon":"🏨"
                },
                {
                    "title":"Urus Visa Umrah",
                    "icon":"🪪"
                },
            ]

            }
        }
    }
}
</script>