<template>
    <div class="testimonial-card border border-gray-200 rounded-[18px] p-4 w-[276px] h-[240px] mr-4 flex flex-col">
        <div class="tick-icon mb-5">
            <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.14286 7.29322C4.53857 7.29322 3.96696 7.41589 3.42857 7.60789V7.29322C3.42857 5.41055 4.96607 3.87989 6.85714 3.87989C7.80375 3.87989 8.57143 3.11562 8.57143 2.17322C8.57143 1.23082 7.80536 0.466553 6.85714 0.466553C3.07607 0.466553 0 3.52789 0 7.29322V12.4132C0 15.241 2.3025 17.5332 5.14286 17.5332C7.98321 17.5332 10.2857 15.241 10.2857 12.4132C10.2857 9.58549 7.98214 7.29322 5.14286 7.29322ZM18.8571 7.29322C18.2529 7.29322 17.6812 7.41615 17.1429 7.60677V7.29322C17.1429 5.41055 18.6804 3.87989 20.5714 3.87989C21.518 3.87989 22.2857 3.11562 22.2857 2.17322C22.2857 1.23082 21.518 0.466553 20.5714 0.466553C16.7904 0.466553 13.7143 3.52895 13.7143 7.29322V12.4132C13.7143 15.241 16.0168 17.5332 18.8571 17.5332C21.6975 17.5332 24 15.241 24 12.4132C24 9.58549 21.6964 7.29322 18.8571 7.29322Z"
                    fill="#AB1700" />
            </svg>
        </div>

        <div class="testimonial-text mb-auto h-[160px] overflow-y-auto">
            <p class="text-left">
                {{ text }}
            </p>
        </div>

        <div class="user-info flex flex-col mt-1">
            <span class="user-name mb-1">
                <p class="text-left font-bolder">
                    <b>
                        {{ maskedName }}
                    </b>
                </p>
            </span>

            <div class="flex flex-row">
                <span class="user-location inline-flex items-center gap-[4px]">
                    <b>
                        {{ paket }}
                    </b>
                </span>
                <div class="my-auto">
                    <span class="separator w-[1px] h-[16px] text-[#E8E8E8] my-auto mx-[8px]"> | </span>
                </div>
                <span class="date text-gray-600">
                    <p>
                        {{ formattedDateRange }}
                    </p>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestimonyCard',
    props: {
        text: String,
        name: String,
        paket: String,
        date_start: String,
        date_end: String,
    },
    computed: {
        maskedName() {
            return this.name.split(' ').map(word => {
                if (word.length > 1) {
                    return word.charAt(0) + '*'.repeat(word.length - 1);
                }
                return word;
            }).join(' ');
        },
        formattedDateRange() {
            return this.formatDateRange(this.date_start, this.date_end);
        },
    },
    methods: {
        formatDateRange(start, end) {
            const startDate = new Date(start);
            const endDate = new Date(end);

            if (isNaN(startDate) || isNaN(endDate)) {
                return 'Invalid date';
            }

            const startDay = startDate.getDate();
            const endDay = endDate.getDate();
            const startMonth = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(startDate);
            const endMonth = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(endDate);
            const startYear = startDate.getFullYear();
            const endYear = endDate.getFullYear();

            if (startYear !== endYear) {
                return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
            } else if (startMonth !== endMonth) {
                return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
            } else {
                return `${startDay} - ${endDay} ${endMonth} ${startYear}`;
            }
        },
    },
};
</script>

<style scoped>
/* Remove the scoped styles as they are now replaced by Tailwind CSS classes */
</style>