<template>
  <div class="bg-white rounded-lg shadow-md overflow-hidden">
    <img 
      :src="image" 
      :alt="title" 
      class="w-full h-48 object-cover object-center"
    >
    <div class="px-4 py-2 text-center">
      <h3 class="text-gray-800 font-semibold">{{ title }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "EquipmentCard",
  props: {
    title: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    }
  }
}

</script>