<template>
  <div class="px-8 my-6">
    <h2 class="font-semibold mb-4">Dokumentasi Perjalanan</h2>
    <div class="max-w-4xl mx-auto p-4">
      <div class="w-full h-[400px] mb-6 overflow-hidden rounded-lg shadow-lg">
        <img 
          :src="selectedImage" 
          alt="Selected Image" 
          class="w-full h-full object-cover object-center transition-all duration-300"
        />
      </div>
  
      <!-- Thumbnail carousel -->
      <Splide
        :options="thumbnailOptions"
        class="thumbnail-slider"
        @moved="handleThumbnailMove"
      >
        <SplideSlide
          v-for="(image, index) in images"
          :key="index"
          @click="selectImage(image)"
          class="cursor-pointer transition-all duration-300 hover:opacity-100"
          :class="selectedImage === image ? 'ring-2 ring-blue-500 opacity-100' : 'opacity-70'"
        >
          <img 
            :src="image" 
            :alt="`Thumbnail ${index + 1}`"
            class="w-full h-24 object-cover object-center rounded-md"
          />
        </SplideSlide>
      </Splide>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';

export default {
  name: 'ThumbnailGallery',
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      selectedImage: null,
      images: [
        'https://picsum.photos/200/300?random=1',
        'https://picsum.photos/200/300?random=2',
        'https://picsum.photos/200/300?random=3',
        'https://picsum.photos/200/300?random=4',
        'https://picsum.photos/200/300?random=5',
        'https://picsum.photos/200/300?random=6',
        'https://picsum.photos/200/300?random=7',
        'https://picsum.photos/200/300?random=8',
      ],
      thumbnailOptions: {
        rewind: true,
        gap: '1rem',
        perPage: 5,
        perMove: 1,
        pagination: false,
        breakpoints: {
          768: {
            perPage: 3,
          },
          480: {
            perPage: 2,
          },
        },
      },
    };
  },
  mounted() {
    if (this.images.length > 0) {
      this.selectedImage = this.images[0];
    }
  },
  methods: {
    selectImage(image) {
      this.selectedImage = image;
    },
    handleThumbnailMove(splide, newIndex) {
      console.log('Moved to index:', newIndex);
    },
  },
};
</script>

<!-- Add any custom styles that can't be handled by Tailwind -->
<style>
.splide__arrow {
  @apply bg-white/80 hover:bg-white/90 transition-colors duration-300;
}

.splide__arrow svg {
  @apply w-4 h-4 fill-gray-700;
}

/* Optional: Custom scrollbar styles */
.splide__track {
  @apply scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200;
}
</style>