<template>
    <div class="bg-[#890200] rounded-xl p-6 text-center text-white mb-6 mx-4">
      <h2 class="font-semibold leading-relaxed mb-4 min-[320px]:text-left lg:text-center">
        Butuh konsultasi visa terlebih dahulu? Qvisa siap membantu
      </h2>

      <p class="mb-4 min-[320px]:text-left lg:text-center">
        Pakai jasa Qvisa oleh PT Kisarah Anugerah Wisata yang dikhususkan untuk pengurusan visa ke luar negeri
      </p>

      <Button href="qvisa.id" size="large" variant="primary-white" class="mx-auto lg:w-1/4">Layanan Qvisa
      </Button>
 
    </div>
  </template>
  
  <script>
import Button from './base/Button.vue';

  export default {
    name: "ConsultationBox",
    components: {
        Button
    }
  };
  </script>
  
  <style scoped>
  .consult-box {
    background-color: #1E1E8E;
  }
  
  .consult-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .office-button {
    display: inline-block;
    text-align: center;
  }
  </style>
  