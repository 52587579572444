<template>
  <nav class="bg-[#F8F8F8] px-4 py-3 sticky top-0 shadow-sm z-50">
    <div class="container mx-auto flex justify-between items-center">
      <img src="@/assets/kisarah_logo.png" alt="Kisarah logo" class="h-8 md:h-10 w-auto object-cover" />
      <CustomButton href="https://wa.me/62811127575" size="small" variant="primary" icon="fab fa-whatsapp">Konsultasi
      </CustomButton>
    </div>
  </nav>
</template>

<script>
import CustomButton from './Button.vue';

export default {
  name: "HeaderSection",
  components: {
    CustomButton
  },
};
</script>