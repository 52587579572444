<template>
    <div id="hero-section" class="flex flex-col md:flex-row bg-[#F8F8F8] rounded-2xl md:rounded-3xl">
        <div id="left-container" class="p-4 py-6 md:p-12 md:pr-0 flex flex-col gap-6 md:gap-8 flex-1 h-fit">
            <div class="flex flex-col gap-3 md:gap-4 max-w-[90%]">
                <h1>
                    Umroh terjangkau, nyaman, dan sesuai sunnah
                </h1>
                <h6 class="text-[#666666]">Kisarah memberikan pelayanan terbaik demi kenyamanan ibadah Anda dan keluarga</h6>
            </div>
            <div class="flex flex-col md:flex-row gap-3 md:max-w-[360px]">
                <CustomButton class="w-full" href="https://wa.me/62811127575" size="large" variant="primary" icon="fab fa-whatsapp">
                    Konsultasi
                </CustomButton>
                <CustomButton class="w-full" href="#" size="large" variant="secondary" targetId="services-section">
                    Lihat Paket
                </CustomButton>
            </div>
        </div>
        <div id="image-container" class="flex-1 flex">
            <img src="@/assets/hero-desktop.png" alt="Hero asset" class="w-full h-full object-cover" />
        </div>
    </div>
</template>

<script>
import CustomButton from '../base/Button.vue';

export default {
    name: "HeroSection",
    components: {
        CustomButton
    }
}

// Responsive hero image
function syncHeights() {
    const kiriDiv = document.getElementById('left-container');
    const imageContainer = document.getElementById('image-container');

    const kiriHeight = kiriDiv.offsetHeight;

    imageContainer.style.height = kiriHeight + 'px';
}
window.addEventListener('load', syncHeights);
window.addEventListener('resize', syncHeights);
</script>

<style scoped>
#hero-section {
    width: 90vw;
    margin: 5vh auto;
    overflow: hidden;
}

/* Media queries */
@media (min-width: 768px) {}
</style>