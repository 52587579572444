<template>
    <div class="px-8 my-6">
        <h2 class="font-semibold mb-4">Untuk setiap paket umroh, Anda akan mendapatkan</h2>
        <div class="grid grid-cols-5 min-[320px]:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-4">
            <EquipmentCard
                v-for="(equipment, index) in kisarahData.equipment"
                :key="index"
                :title="equipment.title"
                :image="equipment.img_url"
            />
        </div>
    </div>

</template>

<script>
import EquipmentCard from '../EquipmentCard.vue';

export default {
  name: "EquipmentSection",
  components: {
    EquipmentCard
  },
  data() {
    return {
        kisarahData: {
            equipment: [
                {
                    "title":"Hijab",
                    "img_url":"https://awsimages.detik.net.id/community/media/visual/2023/11/21/pemenang-emeron-hijab-hunt-2023-menjalani-ibadah-umroh-2_43.jpeg?w=1200"
                },
                {
                    "title":"Kain Ihram",
                    "img_url":"https://www.static-src.com/wcsstore/Indraprastha/images/catalog/full//101/MTA-36502399/no_brand_baju_ihrom_dewasa_kain_ihram_satu_stel_bahan_handuk_full01_p0457ws1.jpg"
                },
                {
                    "title":"Buku Doa-Doa",
                    "img_url":"https://toko-muslim.com/images/product/2021/05/1.png"
                }
            ],
            services: [
                {
                    "title":"Tiket Pesawat PP",
                    "icon":"✈️"
                }
            ]

            }
        }
    }
}
</script>