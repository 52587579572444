<template>
    <div class="px-8 my-6">
        <h2 class="font-semibold mb-4">Pilihan Paket Umrah</h2>
        <div class="flex flex-row py-5 overflow-x-scroll space-x-2 [scrollbar-width:none] [-ms-overflow-style:none]">
            <div 
                v-for="(type, idx) in ['Semua', 'Silver', 'Gold', 'Platinum']"
                :key="idx"
                @click="selectedFilter = type" 
                :class="{'bg-[#233863] text-white': selectedFilter === type}" 
                class="px-3 py-2 rounded-lg border-[1px] border-[#DAE0E9] cursor-pointer"
            >
                {{ type }}
            </div>
        </div>
        <div class="overflow-x-scroll flex flex-row space-x-4 [scrollbar-width:none] [-ms-overflow-style:none]">
            <PackageCard v-for="(pkg, index) in filteredPackages" :key="index" :title="pkg.packageName"
                :subtitle="pkg.packageDesc" :labels="pkg.labels" :image="pkg.imageUrl" />
        </div>
    </div>
</template>

<script>
import PackageCard from '../PackageCard.vue';

export default {
    name: "PackageSection",
    components: {
        PackageCard
    },
    computed: {
        filteredPackages() {
            if (this.selectedFilter === 'Semua') return this.kisarahData.packages;
            return this.kisarahData.packages.filter(
                pkg => pkg.labels.map(
                    label => label.title)
                    .includes(this.selectedFilter));
        }
    },
    data() {
        return {
            selectedFilter: 'Semua',
            kisarahData: {
                equipment: [
                    {
                        "title": "Hijab",
                        "img_url": "https://awsimages.detik.net.id/community/media/visual/2023/11/21/pemenang-emeron-hijab-hunt-2023-menjalani-ibadah-umroh-2_43.jpeg?w=1200"
                    },
                    {
                        "title": "Kain Ihram",
                        "img_url": "https://www.static-src.com/wcsstore/Indraprastha/images/catalog/full//101/MTA-36502399/no_brand_baju_ihrom_dewasa_kain_ihram_satu_stel_bahan_handuk_full01_p0457ws1.jpg"
                    },
                    {
                        "title": "Buku Doa-Doa",
                        "img_url": "https://toko-muslim.com/images/product/2021/05/1.png"
                    }
                ],
                services: [
                    {
                        "title": "Tiket Pesawat PP",
                        "icon": "✈️"
                    }
                ],
                packages: [
                    {
                        "packageName": "Umroh Silver Package",
                        "packageDesc": "9-18 November 2024",
                        "labels": [
                            {
                                "title": "Silver",
                                "style": "bg-black"
                            },
                            {
                                "title": "Ramadhan",
                                "style": "bg-green-300"
                            }
                        ],
                        "imageUrl": "https://lp.waroengslide.com/wp-content/uploads/2023/05/Slide2-2.jpg"
                    },
                    {
                        "packageName": "Umroh Gold Package",
                        "packageDesc": "9-18 November 2024",
                        "labels": [
                            {
                                "title": "Gold",
                                "style": "bg-yellow-500"
                            },
                            {
                                "title": "Ramadhan",
                                "style": "bg-green-300"
                            }
                        ],
                        "imageUrl": "https://lp.waroengslide.com/wp-content/uploads/2023/05/Slide2-2.jpg"
                    },
                    {
                        "packageName": "Umroh Platinum Package",
                        "packageDesc": "9-18 November 2024",
                        "labels": [
                            {
                                "title": "Platinum",
                                "style": "bg-red-500"
                            },
                            {
                                "title": "Ramadhan",
                                "style": "bg-green-300"
                            }
                        ],
                        "imageUrl": "https://lp.waroengslide.com/wp-content/uploads/2023/05/Slide2-2.jpg"
                    }

                ]

            }
        }
    }
}
</script>