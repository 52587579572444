<template>
    <div id="app">
    <!-- Router View: This is where the matched component will be displayed -->
    <main>
      <HeaderSection />
      <router-view></router-view>
      <FooterSection />
    </main>
  </div>
</template>

<script>
import HeaderSection from './components/base/HeaderSection.vue';
import FooterSection from './components/base/FooterSection.vue';

export default {
  name: 'App',
  components: {
    HeaderSection,
    FooterSection,
  }
}
</script>

