<template>
    <div :class="['px-3 py-1 rounded-full text-white font-semibold', styleClass]">
        <p>{{ labelName }}</p>
    </div>
</template>

<script>
export default {
    name: "LabelCard",
    props: {
        labelName: String,
        styleClass: String
    }
}
</script>