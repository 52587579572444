<template>
    <div id="testimony-section" class="my-[5vw] ml-[5vw]">
        <div class="mb-5">
            <h2 class="text-left text-xl font-bold">
                Kata mereka yang sudah umroh bersama Kisarah
            </h2>
        </div>

        <div class="testimony-list flex flex-row overflow-x-auto mb-10">
            <div class="testimony-card-wrapper" v-for="testimony in testimonies" :key="testimony._id">
                <TestimonyTile :text="testimony.text" :name="testimony.name" :paket="testimony.paket"
                    :date_start="testimony.date_start" :date_end="testimony.date_end" />
            </div>
        </div>

    </div>
</template>

<script>
import TestimonyCard from '../cards/TestimonyCard.vue';

export default {
    name: 'TestimonySection',
    components: {
        TestimonyTile: TestimonyCard,
    },
    data() {
        return {
            testimonies: [
                {
                    id: 1,
                    text: "Sangat puas dengan Qvisa! Dokumen jelas dan tidak rumit, Visa saya selesai lebih cepat. Hebat! Terima kasih Qvisa",
                    name: "Astrida Nayla",
                    paket: "Umroh Gold",
                    date_start: "2021-08-01",
                    date_end: "2021-08-10",
                },
                {
                    id: 2,
                    text: "Sangat puas dengan Qvisa! Dokumen jelas dan tidak rumit, Visa saya selesai lebih cepat. Hebat! Terima kasih Qvisa",
                    name: "Astrida Nayla",
                    paket: "Umroh Gold",
                    date_start: "2021-08-01",
                    date_end: "2021-08-10",
                },
                {
                    id: 3,
                    text: "Sangat puas dengan Qvisa! Dokumen jelas dan tidak rumit, Visa saya selesai lebih cepat. Hebat! Terima kasih Qvisa",
                    name: "Astrida Nayla",
                    paket: "Umroh Gold",
                    date_start: "2021-08-01",
                    date_end: "2021-08-10",
                },
                {
                    id: 4,
                    text: "Sangat puas dengan Qvisa! Dokumen jelas dan tidak rumit, Visa saya selesai lebih cepat. Hebat! Terima kasih Qvisa",
                    name: "Astrida Nayla",
                    paket: "Umroh Gold",
                    date_start: "2021-08-01",
                    date_end: "2021-08-10",
                },
                {
                    id: 5,
                    text: "Sangat puas dengan Qvisa! Dokumen jelas dan tidak rumit, Visa saya selesai lebih cepat. Hebat! Terima kasih Qvisa",
                    name: "Astrida Nayla",
                    paket: "Umroh Gold",
                    date_start: "2021-08-01",
                    date_end: "2021-08-10",
                },
            ],
        };
    },
    async created() {
        // try {
        //     const response = await fetch(`${this.$baseApiUrl}/api/testimonies`);
        //     this.testimonies = await response.json();
        // } catch (error) {
        //     console.error('Error fetching testimonies:', error);
        // }
    }
};
</script>

<style scoped></style>