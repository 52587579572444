<template>
  <div>
    <HeroSection />
    <ServicesSection />
    <PackageSection />
    <EquipmentSection />
    <DocumentationSection />
    <TestimonySection />
    <ConsultationBox />
  </div>
</template>

<script>
import EquipmentSection from '@/components/section/EquipmentSection.vue';
import HeroSection from '@/components/section/HeroSection.vue';
import PackageSection from '@/components/section/PackageSection.vue';
import ServicesSection from '@/components/section/ServicesSection.vue';
import TestimonySection from '@/components/section/TestimonySection.vue';
import ConsultationBox from '@/components/ConsultationBox.vue';
import DocumentationSection from '@/components/section/DocumentationSection.vue';


export default {
  name: 'HomePage',
  components: {
    PackageSection,
    HeroSection,
    ServicesSection,
    EquipmentSection,
    DocumentationSection,
    TestimonySection,
    ConsultationBox
  },
};
</script>